import React, { useState, useEffect } from "react";
import fetchJsonp from "fetch-jsonp";
import { Link } from "gatsby";

import { useI18nContext } from "@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n";

require("es6-promise").polyfill();

const SmartFeedJobAlerts = (props) => {
  const { i18nState: lang, t } = useI18nContext();

  const [jaDetails, setJaDetails] = useState({
    CompanyGroupName: props.CompanyGroupName,
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    keywords: "",
    geoLocation: "",
    Location: [],
    Region: [],
    Category: [],
    Industry: [],
    JobType: [],
    JobTime: [],
    MailFrequency: 86400000,
    acceptGDPR: false,
  });

  const groupOrIdParam = props.group ? "group" : "id";

  const [filterData, setFilterData] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [jobTypeData, setJobTypeData] = useState([]);
  const [jobTimeData, setJobTimeData] = useState([]);

  const [showGDPR, setShowGDPR] = useState(false);
  const [GDPRStatement, setGDPRStatement] = useState("");
  const [apiResponse, setApiResponse] = useState("NA");

  const [error, setError] = useState("");

  useEffect(() => {
    if (props.group) {
      if (props.location) {
        getApiData("location", "GetLocationListByGroup", setLocationData);
      }
      if (props.region) {
        getApiData("region", "GetRegionListByGroup", setRegionData);
      }
      if (props.category) {
        getApiData("category", "GetCategoryListByGroup", setCategoryData);
      }
      if (props.industry) {
        getApiData("industry", "GetIndustryListByGroup", setIndustryData);
      }
      if (props.jobType) {
        getApiData("JobType", "GetJobTypeListByGroup", setJobTypeData);
      }
      if (props.jobTime) {
        getApiData("JobTime", "GetJobTimes", setJobTimeData);
      }
    } else {
      if (props.location) {
        getApiData("location", "GetAllLocationsListByCompany", setLocationData);
      }
      if (props.region) {
        getApiData("region", "GetAllRegionsListByCompany", setRegionData);
      }
      if (props.category) {
        getApiData(
          "category",
          "GetAllCategoriesListByCompany",
          setCategoryData
        );
      }
      if (props.industry) {
        getApiData(
          "industry",
          "GetAllIndustriesListByCompany",
          setIndustryData
        );
      }
      if (props.jobType) {
        getApiData("JobType", "GetJobTypes", setJobTypeData);
      }
      if (props.jobTime) {
        getApiData("JobTime", "GetJobTimes", setJobTimeData);
      }
    }

    getGDPRApiData();
  }, [lang]);

  const changeGDPRVal = () => {
    let temp = { ...jaDetails };

    temp.acceptGDPR = !temp.acceptGDPR;

    setJaDetails(temp);
  };

  const getApiData = (name, apiCall, setState) => {
    if (apiCall === "GetJobTimes") {
      const jsonList = [
        t("job-alerts.job-times.full-time"),
        t("job-alerts.job-times.part-time"),
        t("job-alerts.job-times.term-time"),
        t("job-alerts.job-times.variable-hours"),
        t("job-alerts.job-times.sessional"),
        t("job-alerts.job-times.fractional-hours"),
      ];

      const uniqueArray = [];

      uniqueArray.push(t("job-alerts.all"));

      jsonList.map((item, i) => {
        if (!uniqueArray.includes(item)) {
          uniqueArray.push(item);
        }
      });

      setState(uniqueArray);
    } else {
      fetchJsonp(
        props.siteConfig.sfAPIEndpoint +
          "/CareerPage/" +
          apiCall +
          "?" +
          groupOrIdParam +
          "=" +
          props.apiKey +
          "&region=All",
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (jsonList) {
          let uniqueArray = [];

          uniqueArray.push(t("job-alerts.all"));

          jsonList.map((item, i) => {
            if (!uniqueArray.includes(item)) {
              uniqueArray.push(item);
            }
          });

          setState(uniqueArray);
        });
    }
  };

  const getGDPRApiData = (name, apiCall, setState) => {
    let gdprID = props.gdprKey || props.apiKey;

    if (props.group === true) {
      gdprID = props.gdprKey;
    }

    fetchJsonp(
      props.siteConfig.sfAPIEndpoint +
        "/CareerPage/GetGdprStatementByCompanyId?encodedCompanyId=" +
        gdprID,
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (gdprStatement) {
        setGDPRStatement(gdprStatement);
      });
  };

  useEffect(() => {
    props.trackEvent({
      eventCategory: "JobAlertsPage",
      eventAction: "initalised",
    });
  }, []);

  const subscribeToJobAlerts = () => {
    const serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };

    let toPass = {
      [props.group ? "GroupId" : "CompanyId"]: props.apiKey,
      TimeZoneDifferenceMinutes: 0,
      CompanyGroupName: props.CompanyGroupName,
      MailFrequency: jaDetails.MailFrequency,
      EmailAddress: jaDetails.EmailAddress,
      FirstName: jaDetails.FirstName,
      LastName: jaDetails.LastName,
      ReturnToSearchURL: props.ReturnToSearchURL,
      VacancyPageBaseURL: props.VacancyPageBaseURL,
      OptInLink: props.OptInLink,
      UnsubscribeLink: props.UnsubscribeLink,
      PostingType: "Careers",
      Location:
        jaDetails.Location[0] && jaDetails.Location[0] === "All"
          ? ""
          : jaDetails.Location.join("|"),
      Region:
        jaDetails.Region[0] && jaDetails.Region[0] === "All"
          ? ""
          : jaDetails.Region.join("|"),
      Industry:
        jaDetails.Industry[0] && jaDetails.Industry[0] === "All"
          ? ""
          : jaDetails.Industry.join("|"),
      Category:
        jaDetails.Category[0] && jaDetails.Category[0] === "All"
          ? ""
          : jaDetails.Category.join("|"),
      Distance: 15,
      Unit: "Miles",
    };

    fetchJsonp(
      props.siteConfig.sfAPIEndpoint +
        "/CareerPage/SubscribeForJobAlert?id=" +
        props.apiKey +
        "&" +
        serialize(toPass),
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        props.trackEvent({
          eventCategory: "JobAlertsPage",
          eventAction: "subscriptionUpdated",
        });
        return response.json();
      })
      .then(function (result) {
        setApiResponse(result);
      });
  };

  const updateField = (name, val) => {
    let temp = { ...jaDetails };

    temp[name] = val;

    setJaDetails(temp);
  };

  const addFilter = (label, item) => {
    let temp = { ...jaDetails };

    if (temp[label].includes(item)) {
      temp[label].splice(temp[label].indexOf(item), 1);
    } else {
      if (item === "All") {
        temp[label] = ["All"];
      } else {
        temp[label].push(item);
      }
    }

    setJaDetails(temp);
  };

  const isItemChecked = (label, item) => {
    if (jaDetails[label].includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  const renderApiData = (label, state) => {
    return (
      <div className="col-12 col-xs-12 col-md-4 ja-filters-check-wrap">
        <div className="ja-filters-check-inner-wrap">
          <div className="ja-filters-section-title">
            <label>{label}:</label>
          </div>
          {state &&
            Array.isArray(state) &&
            state.map((item, i) => (
              <div key={i} className="vf-alerts-check-wrap">
                <input
                  id={label + "_" + item}
                  checked={isItemChecked(label, item)}
                  onChange={() => addFilter(label, item)}
                  type="checkbox"
                  className="vf-alerts-check"
                  name={label}
                  value={item}
                  title={`Select ${label}`}
                  disabled={jaDetails[label].includes("All") && item !== "All"}
                />
                <label
                  className="vf-alerts-check-label"
                  htmlFor={label + "_" + item}
                >
                  {" "}
                  {item}
                </label>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const validateFields = () => {
    return (
      jaDetails.acceptGDPR === true &&
      jaDetails.EmailAddress !== "" &&
      jaDetails.FirstName !== "" &&
      jaDetails.LastName !== ""
    );
  };

  return (
    <div className="VF_Alerts_Wrap smartfeed-forms-form">
      <form
        className="VF_Alerts_Inner_Wrap smartfeed-forms-page"
        onSubmit={(e) => {
          e.preventDefault();
          if (!validateFields()) {
            return setError(t("job-alerts.messages.complete-all-fields"));
          }
          subscribeToJobAlerts();
        }}
      >
        <fieldset className="smartfeed-forms-fieldset">
          <legend>{t("job-alerts.your-details")}</legend>

          <div className="row-fluid">
            <div className="smartfeed-forms-container col-md-12">
              <div className="firstname firstname mandatory mb-2">
                <label
                  htmlFor="job-alerts-firstname"
                  className="smartfeed-forms-label"
                >
                  {t("job-alerts.first-name")}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  id="job-alerts-firstname"
                  type="text"
                  className="VF-form-control form-control"
                  onChange={(e) => updateField("FirstName", e.target.value)}
                  placeholder={t("job-alerts.first-name-placeholder")}
                  title={t("job-alerts.first-name-title")}
                  required
                ></input>
              </div>
              <div className="lastname lastname mandatory alternating mb-2">
                <label
                  htmlFor="job-alerts-lastname"
                  className="smartfeed-forms-label"
                >
                  {t("job-alerts.last-name")}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  id="job-alerts-lastname"
                  type="text"
                  className="VF-form-control form-control"
                  onChange={(e) => updateField("LastName", e.target.value)}
                  placeholder={t("job-alerts.last-name-placeholder")}
                  title={t("job-alerts.last-name-title")}
                  required
                ></input>
              </div>
              <div className="email perplextextfield mandatory mb-2">
                <label
                  htmlFor="job-alerts-email"
                  className="smartfeed-forms-label"
                >
                  {t("job-alerts.email")}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  id="job-alerts-email"
                  type="email"
                  className="VF-form-control form-control"
                  onChange={(e) => updateField("EmailAddress", e.target.value)}
                  placeholder={t("job-alerts.email-placeholder")}
                  title={t("job-alerts.email-title")}
                  required
                ></input>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="smartfeed-forms-fieldset">
          <legend>{t("job-alerts.roles-interested")}</legend>

          <p>{t("job-alerts.select-notifications")}</p>

          {props.category && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>{t("job-alerts.category")}:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.category && (
                    <>{renderApiData("Category", categoryData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          {props.region && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>Region:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.region && <>{renderApiData("Region", regionData)}</>}
                </div>
              </div>
            </div>
          )}
          {props.location && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>{t("job-alerts.location")}:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.location && (
                    <>{renderApiData("Location", locationData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          {props.industry && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>Industry:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.industry && (
                    <>{renderApiData("Industry", industryData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          {props.jobType && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <strong>{t("job-alerts.job-type-time")}:</strong>
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.jobType && (
                    <>{renderApiData("JobType", jobTypeData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
          <hr />
          {props.jobTime && (
            <div className="smartfeed-forms-ja-filter-wrap">
              <div className="row-fluid">
                <div className="smartfeed-forms-container col-md-12">
                  {props.jobTime && (
                    <>{renderApiData("JobTime", jobTimeData)}</>
                  )}
                </div>
              </div>
            </div>
          )}
        </fieldset>
        <fieldset className="smartfeed-forms-fieldset">
          <legend>{t("job-alerts.subscription-statement")}</legend>

          <div className="row-fluid">
            <div className="smartfeed-forms-container col-md-12">
              <div className="gdprStatement">
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.Statement,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: GDPRStatement.GDPRCustomMessage,
                  }}
                />
              </div>
              <div className="acceptGDPRContainer">
                <span className="text-danger">* </span>
                <input
                  type="checkbox"
                  id="job-alerts-gdpr"
                  name="gdpr"
                  value="acceptGDPR"
                  className="acceptGDPR_Check"
                  checked={jaDetails.acceptGDPR}
                  onChange={() => changeGDPRVal()}
                  title={t("job-alerts.agree-gdpr")}
                  required
                />
                <label htmlFor="job-alerts-gdpr">{t("job-alerts.gdpr")}</label>
              </div>
            </div>
          </div>
        </fieldset>

        <div className="job-alert-actions">
          {GDPRStatement && validateFields() ? (
            <div className="d-flex justify-content-between subscribe-btn-wrap">
              {apiResponse === "OK" && (
                <span>{t("job-alerts.messages.signed-up")}</span>
              )}
              {apiResponse === "Error" && (
                <span>{t("job-alerts.messages.error")}</span>
              )}
              {apiResponse === "Updated" && (
                <span>{t("job-alerts.messages.updated-subscription")}</span>
              )}
              {apiResponse === "Pending" && (
                <span>{t("job-alerts.messages.signed-up-check-inbox")}</span>
              )}
              {apiResponse !== "OK" &&
              apiResponse !== "Updated" &&
              apiResponse !== "Pending" ? (
                <input
                  className="nav-action-sm ml-auto"
                  type="submit"
                  value={t("job-alerts.subscribe-to-alerts")}
                />
              ) : (
                <a
                  className="btn btn--primary"
                  href={
                    props.siteConfig.companyCareersSite
                      ? "/"
                      : `/${props.apiKey}/`
                  }
                >
                  {t("job-alerts.back-to-homepage")}
                </a>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-between subscribe-btn-wrap">
              <span className="text-danger">{error}</span>
              <input
                className="nav-action-sm disabled"
                type="submit"
                value={t("job-alerts.messages.please-enter-details")}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default SmartFeedJobAlerts;
