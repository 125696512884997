import React, { useState, useEffect } from "react";

import fetchJsonp from "fetch-jsonp";

import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/Layout";

import SmartFeedJobAlerts from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts";

import { useI18nContext } from "@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n";

import { useCookies } from "react-cookie";
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from "@accrosoft-ltd/vf-careers-site-theme/src/components/Analytics";
import { useTracking } from "react-tracking";

require("es6-promise").polyfill();

const JobAlertsPage = ({
  pageContext: { config },
  location,
  contentDataData,
  smartfeedID,
}) => {
  const { t } = useI18nContext();

  const siteConfig = config;

  const apiKey = smartfeedID || siteConfig.apiKey || siteConfig.smartfeedID;
  const gdprKey =
    smartfeedID ||
    siteConfig.gdprKey ||
    siteConfig.apiKey ||
    siteConfig.smartfeedID;
  const groupOrIdParam = siteConfig.group ? "group" : "id";
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  const [cookies, setCookie] = useCookies(["ap-signals-user-id"]);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    "ap-signals-session-id"
  );

  const { trackEvent } = useTracking(
    { page: "JobAlertsPage", location: location, apiKey: siteConfig.apiKey },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies["ap-signals-user-id"],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackEvent({ eventAction: "initalised" });

    trackAnalyticsPageView(
      location,
      cookies["ap-signals-user-id"],
      sessionIDValue,
      siteConfig
    );
  }, [cookies, location, sessionIDValue, siteConfig, trackEvent]);

  useEffect(() => {
    if (apiKey) {
      let companyDetailsEndpoint = "GetCompanyDetails";

      if (groupOrIdParam === "group") {
        companyDetailsEndpoint = "GetGroupDetails";
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            "/CareerPage/" +
            companyDetailsEndpoint +
            "?id=" +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                "/CareerPage/" +
                "GetApplicantGetwayURL" +
                "?companyId" +
                "=" +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, "error");
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, "error");
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, "error");
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError("No API key was provided.");
    }
  }, [apiKey, gdprKey, siteConfig.sfAPIEndpoint, groupOrIdParam]);

  return (
    <Layout
      siteConfig={siteConfig}
      location={location}
      apiKey={apiKey}
      jobAlertsPage={true}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      title="Subscribe to Job Alerts"
      description="Get notified about our latest job postings"
    >
      {!smartfeedCompanyData ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>{t("job-alerts.messages.please-wait-config")}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : smartfeedCompanyData &&
        !vacanciesRouteLoading &&
        !vacanciesRouteError ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <h1>{t("job-alerts.subscribe-to-alerts")}</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="jobalerts-landing">
            <div className="container">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <div className="smartfeed-spacing">
                    {smartfeedCompanyData.JobAlertCompanySettings ||
                    siteConfig.JobAlertCompanySettings ? (
                      <div className="col-12 col-lg-8 job-alerts-panel">
                        <SmartFeedJobAlerts
                          siteConfig={siteConfig}
                          setVacancyLoading={false}
                          apiKey={siteConfig.apiKey || apiKey}
                          group={siteConfig.group || false}
                          gdprKey={
                            siteConfig.gdprKey || siteConfig.apiKey || apiKey
                          }
                          JobAlertCompanySettings={
                            siteConfig.JobAlertCompanySettings ||
                            smartfeedCompanyData.JobAlertCompanySettings
                          }
                          CompanyGroupName={
                            siteConfig.companyName ||
                            smartfeedCompanyData.CompanyName
                          }
                          OptInLink={
                            siteConfig.companyCareersSite
                              ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
                              : siteConfig.OptInLink ||
                                `${siteConfig.careersSiteDomain}/job-alerts/${apiKey}/optin/?OptInID=`
                          }
                          UnsubscribeLink={
                            siteConfig.companyCareersSite
                              ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
                              : siteConfig.UnsubscribeLink ||
                                `${siteConfig.careersSiteDomain}/job-alerts/${apiKey}/unsubscribe/?UnsubscribeID=`
                          }
                          VacancyPageBaseURL={
                            siteConfig.companyCareersSite
                              ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
                              : siteConfig.VacancyPageBaseURL ||
                                `${siteConfig.careersSiteDomain}/vacancies/${apiKey}/vacancy/`
                          }
                          ReturnToSearchURL={
                            siteConfig.companyCareersSite
                              ? `${siteConfig.careersSiteDomain}/vacancies/`
                              : siteConfig.ReturnToSearchURL ||
                                `${siteConfig.careersSiteDomain}/vacancies/${apiKey}/`
                          }
                          contentDataData={contentDataData || {}}
                          location={siteConfig.showLocationJA}
                          region={siteConfig.showRegionJA}
                          category={siteConfig.showCategoryJA}
                          industry={siteConfig.showIndustryJA}
                          jobType={siteConfig.showJobTypeJA}
                          jobTime={siteConfig.showJobTimeJA}
                          trackEvent={trackEvent}
                        />
                      </div>
                    ) : (
                      <p>{t("job-alerts.messages.could-not-load")}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>{t("job-alerts.optin.please-wait")}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default JobAlertsPage;
